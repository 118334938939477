export default {
  assistants: null,
  assistantsMapped: null,
  archivedAssistants: null,
  archivedAssistantsMapped: null,
  selectedAssistant: {},
  selectedPastChat: {},
  wizard: null,
  member: [],
  pins: [],
  rooms: null,
  pastChats: null,
  engines: null,
  files: null,
  selectedModel: {},
  currentPlan: null,
  fontSize: Number(window.localStorage.getItem('fontSize')) || 14,
  enableAudio: window.localStorage.getItem('enableAudio') == 'true' ? true : false,
  sendOnEnter: window.localStorage.getItem('sendOnEnter') == 'false' ? false : true,
  selectedEngine: window.localStorage.getItem('selectedEngine') || "1",
  ip_address: null,
  deployments: null,
  tokensUsed: 0,
  showWhiteLabel: false,
};
