import { render, staticRenderFns } from "./train-single.vue?vue&type=template&id=243d0c26&scoped=true&"
import script from "./train-single.vue?vue&type=script&lang=js&"
export * from "./train-single.vue?vue&type=script&lang=js&"
import style0 from "./train-single.vue?vue&type=style&index=0&id=243d0c26&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243d0c26",
  null
  
)

export default component.exports