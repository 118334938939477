import config from '@/api-services/_config';

export default {
  getSettings(data) {
    return fetch(`${config.url}/skills:index/get-settings`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },
  updateSettings(data) {
    return fetch(`${config.url}/skills:index/update-settings`, { method: 'POST', body: config.makeFormData(data)}).then(res => res.json());
  },
  
};
