import { render, staticRenderFns } from "./youtube-uploader.vue?vue&type=template&id=043f8a94&scoped=true&"
import script from "./youtube-uploader.vue?vue&type=script&lang=js&"
export * from "./youtube-uploader.vue?vue&type=script&lang=js&"
import style0 from "./youtube-uploader.vue?vue&type=style&index=0&id=043f8a94&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "043f8a94",
  null
  
)

export default component.exports