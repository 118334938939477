<template>
  <transition>
    <div class="loader" v-if="visible && !deployObject?.settings?.showPowered">
      <svg width="259" height="35" viewBox="0 0 259 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M180.839 34.944V0H192.661V34.944H180.839Z" fill="#DC2631"/>
        <path d="M0.700374 34.944C0.313569 34.944 0 34.6306 0 34.244V0.699999C0 0.3134 0.313568 0 0.700374 0H43.5352V2.464C43.5352 5.55679 41.0267 8.06399 37.9322 8.06399H12.5227C12.1359 8.06399 11.8223 8.37739 11.8223 8.76399V12.852C11.8223 13.2386 12.1359 13.552 12.5227 13.552H27.7628C28.1496 13.552 28.4632 13.8654 28.4632 14.252V20.916C28.4632 21.3026 28.1496 21.616 27.7628 21.616H12.5227C12.1359 21.616 11.8223 21.9294 11.8223 22.316V26.18C11.8223 26.5666 12.1359 26.88 12.5227 26.88H42.8349C43.2217 26.88 43.5352 27.1934 43.5352 27.58V34.244C43.5352 34.6306 43.2217 34.944 42.8349 34.944H0.700374Z" fill="#DC2631"/>
        <path d="M168.441 33.8682C168.738 34.3342 168.403 34.944 167.851 34.944H156.835C156.602 34.944 156.385 34.829 156.255 34.637L153.478 30.5469C153.348 30.3549 153.131 30.24 152.899 30.24H129.044C128.812 30.24 128.595 30.3549 128.464 30.5469L125.687 34.637C125.557 34.829 125.34 34.944 125.108 34.944H114.038C113.485 34.944 113.15 34.3335 113.447 33.8675L134.234 1.2943C134.749 0.488049 135.639 0 136.596 0H145.344C146.302 0 147.194 0.489198 147.708 1.29699L168.441 33.8682ZM147.207 22.12C147.765 22.12 148.099 21.5004 147.792 21.035L141.535 11.5378C141.256 11.1152 140.635 11.1184 140.361 11.5438L134.242 21.041C133.942 21.5069 134.277 22.12 134.831 22.12H147.207Z" fill="#DC2631"/>
        <path d="M93.598 26.88C98.054 26.88 101.666 30.4904 101.666 34.944H58.8874C58.5006 34.944 58.187 34.6306 58.187 34.244V29.0728C58.187 27.1808 59.143 25.4166 60.7284 24.3828L85.7538 8.06399H66.1993C61.7433 8.06399 58.131 4.45362 58.131 0H100.966C101.353 0 101.666 0.3134 101.666 0.699999V8.63602C101.666 8.873 101.546 9.09389 101.347 9.22301L74.1556 26.88H93.598Z" fill="#DC2631"/>
        <path d="M203.05 35V29.4H208.681V35H203.05Z" fill="#DC2631"/>
        <path d="M219.147 17.5H225.086V34.972H219.147V17.5Z" fill="#DC2631"/>
        <path d="M234.543 20.636C234.543 20.076 234.674 19.6 234.935 19.208C235.197 18.816 235.542 18.4987 235.972 18.256C236.401 17.9947 236.887 17.808 237.429 17.696C237.97 17.5653 238.53 17.5 239.109 17.5H254.434C254.957 17.5 255.489 17.5653 256.03 17.696C256.591 17.8267 257.086 18.032 257.515 18.312C257.945 18.5733 258.3 18.9 258.58 19.292C258.86 19.684 259 20.132 259 20.636V31.836C259 32.34 258.86 32.788 258.58 33.18C258.3 33.572 257.945 33.908 257.515 34.188C257.086 34.4493 256.591 34.6453 256.03 34.776C255.489 34.9066 254.957 34.972 254.434 34.972H239.109C238.53 34.972 237.97 34.916 237.429 34.804C236.887 34.6733 236.401 34.4866 235.972 34.244C235.542 33.9826 235.197 33.656 234.935 33.264C234.674 32.872 234.543 32.396 234.543 31.836V20.636ZM251.66 30.94C252.434 30.94 253.061 30.3132 253.061 29.54V22.932C253.061 22.1588 252.434 21.532 251.66 21.532H241.855C241.081 21.532 240.454 22.1588 240.454 22.932V29.54C240.454 30.3132 241.081 30.94 241.855 30.94H251.66Z" fill="#DC2631"/>
      </svg>
    </div>
    <div class="loader" v-else-if="visible">
      <svg width="259" height="35" viewBox="0 0 259 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      </svg>
    </div>
  </transition>
</template>

<script>
import config from '@/api-services/_config';

export default {
  name: 'GlobalLoader',
  props: {
    visible: {
      default: false,
    },
    deployData: {
      default: null,
    }
  },
  data() {
    return {
    }
  },
  mounted(){
    console.log(this.deployKey);
    
  },
  computed: {
    isDeployedApp() {
      return config.isDeployedApp;
    },
    deployKey() {
      return config.deployKey;
    },
    deployObject() {
        if(!config.isDeployedApp){
          return {};
        }
        return this.$parent.deployObject;
      },
  },
}
</script>

<style scoped lang="scss">
  .loader {
    background: var(--bs-body-bg);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    &.v-enter-active,
    &.v-leave-active {
      transition: opacity .5s;
    }
    &.v-enter-from,
    &.v-leave-to {
      opacity: 0;
    }
    svg {
      opacity: 0;
      overflow: visible;
      animation: .5s fadeIn 0s linear, 5s slowZoomIn 0s linear;
      animation-fill-mode: forwards;
    }
  }
  @keyframes slowZoomIn {
    0% { transform: scale(1); }
    100% { transform: scale(2); }
  }
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
</style>